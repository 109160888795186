import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Stept1 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Launch video | ${titleTrainingDays}`}
        description={"Launch video by the principal of the Ramón y Cajal Highschool, Guillermo Sánchez Guíu."}
        canonical={"training-days/step-1/"}
      />
      <div className="slideLeft container step">
        <p className="slideLeft s05 h1">{titleTrainingDays} - Step 1</p>
        <h1 className="slideLeft s1">Launch video</h1>
        <section className="videoCart">
          <main>
            <div className={"video--bloque--a"}></div>
            <div className="embed-iframe">
              <LazyIframe src={"GkHT-yFFGXE"} title={"Launch video"} />
            </div>
          </main>
          <aside className={"slideLeft s2"}>
            <div className="stepNavigation ">
              <Link to="/training-days/" className={"slideBack s11 stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-2/#top" className={"slideLeft s11 stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p className={"padding"}>
              Launch video by the principal of the Ramón y Cajal Highschool, Guillermo Sánchez Guíu.
            </p>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Stept1;
